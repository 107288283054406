import React from 'react'
import UrlShortner from './Tool Section/UrlShortner'

const HomeBannerFrame = () => {
  return (
    <React.Fragment>
      <div className='bg-Transparent1'>
        <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
          <div className=' grid grid-cols-1 mx-[5%] pt-[10%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-[0.85]'>
            <UrlShortner />
            <div className='grid grid-cols-1 py-[2%] mt-4 pb-[7%] items-center justify-items-center gap-5 sm:gap-2'>
              <div className='grid grid-cols-1 gap-2 max-w-full sm:max-w-3xl'>
                <p className='text-center text-sm sm:text-lg'>Don’t look further if you want to convert your messy-looking Links to short-form URLs; just visit Toolstrain.com.</p>
                <p className='text-sm sm:text-lg text-justify font-bold'>Benefits of Short URLs:</p>
                <ul className='list-disc pl-4 grid gap-2'>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>1. Higher CTR: More clicks due to cleaner, shareable links.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>2. User-Friendly: Easy to remember and type.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>3. Better Tracking: Enhanced analytics for performance insights.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>4. Shareable: Ideal for platforms with character limits.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>5. Brand Boost: Custom links reinforce your brand.</h4>
                  </li>
                </ul>
                <p className='text-sm sm:text-lg text-justify'>With Our TinyURL Shortener, you don’t have to face the difficulty of remembering longer URLs. Meanwhile, don’t worry about the payment plans because it's totally FREE. You can use our Best URL Shortener for Free!</p>
                <p className='text-sm sm:text-lg text-justify font-bold'>Compatible with All Platforms:</p>
                <ul className='list-disc pl-4 grid gap-2'>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>1. Works on websites, social media, emails, and SMS.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>2. Ideal for academic links and more.</h4>
                  </li>
                  <li className='grid grid-cols-1 space-y-2'>
                    <h4>3. The perfect alternative to Bitly and t.ly.</h4>
                  </li>
                </ul>
                <p className='text-sm sm:text-lg text-justify font-bold'>ToolsTrain Overview:</p>
                <p className='text-sm sm:text-lg text-justify'>ToolsTrain offers a suite of free online tools, including a URL shortener, converters, image compressors, and more. All tools provide unlimited, high-quality, watermark-free results. With around 14+ Tools, our aim is to provide great service to the people who need our help.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomeBannerFrame;
