import ImageToTextIcon from '../assets/icons/image_file.svg';
import PdftoWordConvertorIcon from '../assets/icons/pdf.svg';
import WordtoPdfConvertorIcon from '../assets/icons/microsoft_word.svg';
import ConverttoPngIcon from '../assets/icons/png.svg';
import {createContext} from 'react';

export const DocConversationContext = createContext();

const DocConversationState = (props) => {
    const DocumentConversionTools = [
        {
          icon: ImageToTextIcon,
          alt: 'Effortlessly use our Image to text ai converter Online Toolstrain.com',
          focusheading: 'Image to Text Converter Free Online',
          heading: 'Effortlessly use our Image to text ai converter Online.',
          description: 'To Convert Your Images to Text, you can visit Toolstrain and Use Our Best image-to-text Converter Free Online. Free Image-to-Text Extractor by Toolstrain.',
          ToPath: '/image-to-text-convertor',
        },
        {
          icon: PdftoWordConvertorIcon,
          alt: 'Best Free Pdf to Word Converter - Fast & Accurate Toolstrain.com',
          focusheading: 'PDF to Word Free Converter',
          heading: 'Best Free Pdf to Word Converter - Fast & Accurate.',
          description: 'Don’t Struggle Anymore while Converting Your PDF to Word files. Just Use Our Best PDF to Word Free Converter and see the magic happening!',
          ToPath: '/pdf-to-word-convertor',
        },
        {
          icon: WordtoPdfConvertorIcon,
          alt: 'Word To Pdf Converter Online Free - Free Tool Toolstrain.com',
          focusheading: 'Word To PDF Converter Free',
          heading: 'Word To Pdf Converter Online Free - Free Tool.',
          description: 'You can now easily Convert Word files to PDF Free! Simply visit Toolstrain and search for our best free PDF converter for Word to PDF online.',
          ToPath: '/word-to-pdf-convertor',

        },
        {
          icon: ConverttoPngIcon,
          alt: 'Best Free Tool to Convert Image To Png Easily Toolstrain.com',
          focusheading: 'Convert Image To PNG',
          heading: 'Best Free Tool to Convert Image To Png Easily',
          description: 'When it Comes to Converting Image to Png you worries are Over! Just Click on Our Free Tool to Convert Image to PNG and Use it in Your Projects!',
          ToPath: '/convert-into-png',
        },
        {
          icon: ConverttoPngIcon,
          alt: 'Free Image Compressor Online - Without Signup Toolstrain.com',
          focusheading: 'Image Compressor Online',
          heading: 'Free Image Compressor Online - Without Signup',
          description: 'Working on Blogs or Other Projects we need a Reliable and Free Image Size Compressor Online. So we can compress the size of image without losing pixels.',
          ToPath: '/image-file-compressor',
        },
    ];
    return (
        <DocConversationContext.Provider value={DocumentConversionTools}>
            {props.children}
        </DocConversationContext.Provider>
    )
}
export default DocConversationState;