import React, { useState, useEffect } from 'react';
import LoadingScreen from '../../Utilities/LoadingScreen';
import { useLoading } from '../../context/LoadingContext';

const UrlShortner = () => {
  const { showLoading, hideLoading } = useLoading();
  const [submited, setSubmitt] = useState(false);
  const [urlShortnerInput, setUrlShortnerInput] = useState('');
  const [urlShortnerOutput, setUrlShortnerOutput] = useState('');
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const copyToClipboard = () => {
    const outputField = document.getElementById('urlShortnerOutput');
    if (outputField) {
      outputField.select();
      document.execCommand('copy');
    }
  };

  const generateShortnerURL = async () => {
    try {
      showLoading();
      setSubmitt(true);
      const response = await fetch('https://toolstrain.xyz/UrlShortener/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(urlShortnerInput),
      });

      if (!response.ok) {
        throw new Error('Error shortening URL');
      }

      const result = await response.text();
      setUrlShortnerOutput(result);
    } catch (error) {
      setUrlShortnerOutput('Error: contact with admin. toolstrain@gmail.com');
    } finally {
      setSubmitt(false);
      hideLoading();
    }
  };

  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1'>
      <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
        <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative leading-6'>
          <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
          Turn Your Long URL into a Short URL with Our Best Free URL Shortener!
          <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
        </h2>
        <h3 className='text-center text-sm sm:text-lg'>We know you need help remembering those big URLs, but they are not even presentable. So, for a unique URL and short-size URL, you can enter your long URL using our URL shortener tool and get a short link in no time.        </h3>
        
        <div className='grid grid-cols-1 w-full'>
            <div className='bg-White grid grid-cols-[1fr_auto] gap-4'>
            <input
              type='url'
              value={urlShortnerInput}
              onChange={(e) => setUrlShortnerInput(e.target.value)}
              placeholder='Paste your URL here...'
              className='py-2 sm:py-3 px-4 outline-none w-full rounded-md'
            />
              <button
                className='border-2 border-Primary px-2 py-1 my-1 mr-1 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block'
                onClick={generateShortnerURL}
              >
              Generate
              </button>
            </div>
        </div>
        {submited ? (
          <LoadingScreen />
        ) : (
          <div className='grid grid-cols-1 w-full'>
            <div className='bg-White grid grid-cols-[1fr_auto] gap-4'>
              <input
                  id='urlShortnerOutput'
                  type='url'
                  value={urlShortnerOutput}
                  className='py-2 sm:py-3 px-4 outline-none w-full rounded-md bg-none'
                  readOnly
                />
              <button
                className='border-2 border-Primary px-2 py-1 my-1 mr-1 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block'
                onClick={copyToClipboard}
              >
                <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlShortner;

// import React, { useState } from 'react'
// import LoadingScreen from '../../Utilities/LoadingScreen';
// import { useLoading } from '../../context/LoadingContext';

// const UrlShortner = () => {
//   const {showLoading, hideLoading } = useLoading();
//   const [ submited, setSubmitt ] = useState(false);
//   const [urlShortnerInput, SetUrlShortnerInput] = useState("");
//   const [urlShortnerOutput, SetUrlShortnerOutput] = useState("");
//   const GenrateShortnerURL = async () => {
//     try {
//       showLoading();
//       setSubmitt(true);
//       const response = await fetch('https://toolstrain.xyz/UrlShortener/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(urlShortnerInput),
//       });

//       if (!response.ok) {
//         throw new Error('Error shortening URL');
//       }
//       const result = await response.text();
//       SetUrlShortnerOutput(result);
//     } catch (error) {
//       SetUrlShortnerOutput('Error: contact with admin. toolstrain@gmail.com');
//     }
//     finally{
//       setSubmitt(false);
//       hideLoading();
//     }
//   };

//   return (
//     <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1'>
//         <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
//             {/* <h2 className='text-center  text-White font-bold uppercase before:border-b-2 before:border-White before:pl-2 after:border-b-2 after:border-White after:pl-2'>Url Shortner</h2> */}
//             <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
//               <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
//               Url Shortener
//               <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
//             </h2>
//             <h3 className='text-center text-sm sm:text-lg'>This is short url description will written here...</h3>
//            <div className='grid grid-cols-[1fr_auto] w-full gap-4'>
//                 <input type='url' value={urlShortnerInput} onChange={(e)=>SetUrlShortnerInput(e.target.value)} placeholder='Past your url here...' className='py-2 sm:py-3 px-4 outline-none w-full rounded-md'/>
//                 <button className='border-2 border-White px-2 py-1 rounded-md text-White hover:bg-Primary' onClick={GenrateShortnerURL}>Generate</button>
//            </div>
//            {submited ? <LoadingScreen /> : <input type='url' value={urlShortnerOutput} className='py-2 sm:py-3 px-4 outline-none w-full  rounded-md' readOnly/>}
//         </div>
//     </div>
//   )
// }

// export default UrlShortner;