
import URL from '../assets/icons/website_url.svg';
import  BioProfile from '../assets/icons/fingerprint.svg';
import {createContext} from 'react';

export const ProductivityToolsContext = createContext();
const ProductivityToolsContextState = (props) => {
    const ProductivityTools = [
        {
          icon: URL,
          alt: 'Age Calculator By Date Of Birth: Unlock Your Life Story Toolstrain.com',
          focusheading: 'Free Chronological Age Calculator',
          heading: 'Age Calculator By Date Of Birth: Unlock Your Life Story',
          description: 'Looking Free Biological Age Calculator or Free Chronological Age Calculator? Well, Why Worried? Visit Toolstrain Today To Find Your Exact Age!',
          toPath:'/age-calculator',
        },
        {
          icon: BioProfile,
          alt: 'YouTube Thumbnail Downloader Online: Grab Attention with Clickworthy Images Toolstrain.com',
          focusheading: 'YouTube Thumbnail Downloader',
          heading: 'YouTube Thumbnail Downloader Online: Grab Attention with Clickworthy Images',
          description: 'Looking for Free Youtube Thumbnail Downloader or Youtube Shorts Thumbnail Downloader? Look no Further Than Toolstrain!',
          toPath:'/youtube-thumbnail-downloader',
        },
        {
          icon: BioProfile,
          alt: 'Strong Random Lastpass Password Generator: Safeguard Your Digital World Toolstrain.com',
          focusheading: 'Random Lastpass Password Generator',
          heading: 'Strong Random Lastpass Password Generator: Safeguard Your Digital World',
          description: 'To use the Best Lastpass Generate Password and Generate Password Lastpass You Just have to Visit Toolstrain and Give us all Your Worries!',
          toPath:'/password-genrator',
        }, 
        {
          icon: BioProfile,
          alt: 'Online Free Invoice Generator: Manage Finances Seamlessly Toolstrain.com',
          focusheading: 'Invoice Generator Free Online',
          heading: 'Online Free Invoice Generator: Manage Finances Seamlessly',
          description: 'The Good News is Now You Easily Generate Invoice Online Free! So Why Still Wasting Your Time in Those Hard Softwares? Simply Use Toolstrain Today!',
          toPath:'/invoice-genrator',
        },       
        {
          icon: BioProfile,
          alt: 'Free Hash Tag Generator: Boost Your Social Posts Toolstrain.com',
          focusheading: 'Free Hashtags Generator',
          heading: 'Free Hash Tag Generator: Boost Your Social Posts',
          description: 'When You are about to Post Your Content on Tiktok, Instagram, Facebook, or any other Social Media the First Thing You Need is a Free Hashtags Generator!',
          toPath:'/hash-Tag-genrator',
        },        
        {
          icon: BioProfile,
          alt: 'Online Encoder and Decoder: Break the Language Barrier Toolstrain.com',
          focusheading: 'Decoder Encoder Online',
          heading: 'Online Encoder and Decoder: Break the Language Barrier',
          description: 'You can now encode and decode online for free! With Toolstrain’s Best Free Line, There is no Language Barrier! You will get a Free Decoder Encoder  Online!',
          toPath:'/encoder-decoder',
        },
        {
          icon: BioProfile,
          alt: 'Pdf Word Counter Tool Online: Keep Track of Your Writing Progress Toolstrain.com',
          focusheading: 'Pdf Word Counter',
          heading: 'Pdf Word Counter Tool Online: Keep Track of Your Writing Progress',
          description: 'If you want the best word count in PDF, choose Toolstrain’s PDF Word Counter today! Know the Exact Word Count in the PDF Document.',
          toPath:'/words-counter',
        },
    ];
    return (
        <ProductivityToolsContext.Provider value={ProductivityTools}>
            {props.children}
        </ProductivityToolsContext.Provider>
    )
}
export default ProductivityToolsContextState;