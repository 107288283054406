import React, { useEffect } from 'react'
import HomeBannerFrame from '../sections/HomeBannerFrame'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
// import CategorySliderFrame2 from '../sections/CategorySliderFrame2'
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'
import UrlShortenImg from '../assets/images/Easy to Use Free URL Shortener.png'
import { FaqH4 } from '../components/Headings/FaqH4'
import { FaqPLink } from '../components/Paragraphs/FaqPLink'
import RightButtonIcon from '../assets/icons/right_button1.svg'
import { PLink } from '../components/Paragraphs/PLink'

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  // Define the JSON data outside of the JSX code
  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Toolstrain",
    "url": "https://toolstrain.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://toolstrain.com/{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Free URL Shortener - 14+ Free Online Web Tools by Toolstrain.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Use Toolstrain Free Online Web tools like, Free URL Shortener, Image to Text Converter, Hashtag Generator, Youtube thumbnail download, and more!" />
        <meta name="keywords" content="tools train, web services, text assistance, tiny url, link shortener" />
        <link rel="canonical" href={window.location.href} />
        {/* #S */}
        <meta property="og:title" content="Most Convenient and Easy to Use Free URL Shortener" />
        <meta property="og:site_name" content="Toolstrain" />
        <meta property="og:url" content="https://toolstrain.com" />
        <meta property="og:description" content="Get Free TinyURL shortener, You don’t have to pay a single penny for creating short urls.
        Now, Creating short & memorable links is seconds with our Free URL Shortener for converting lengthy links into good looking, Identifiable and remarkable short URLs." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={UrlShortenImg} />
        <meta name="twitter:card" content="product" />
        <meta name="twitter:site" content="@toolstrain" />
        <meta name="twitter:description" content="Get Free TinyURL shortener, You don’t have to pay a single penny for creating short urls.
        Now, Creating short & memorable links is seconds with our Free URL Shortener for converting lengthy links" />
        <meta name="twitter:app:name:iphone" content="url shortener" />
        <meta name="twitter:app:id:iphone" content="" />
        <meta name="twitter:app:name:ipad" content="url shortener" />
        <meta name="twitter:app:id:ipad" content="" />
        <meta name="twitter:app:name:googleplay" content="url shortener" />
        <meta name="twitter:app:id:googleplay" content="" />
        <meta name="twitter:app:country" content="" />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <HomeBannerFrame />
      <CategorySliderFrame />
      <CategorySliderFrame1 />
      {/* <CategorySliderFrame2 /> */}
      <CategorySliderFrame3 />
      <WhyUs />
      <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
        <div className=' grid grid-cols-1 mx-[5%] mt-5 opacity-95'>
          <div id='Related-Topics' className='grid grid-cols-1 border border-Secoundaryv1 rounded-md mb-5'>
            <FaqH4 txt="FAQ's" className="rounded-xl"/>
            <div className='grid grid-cols-1 gap-2 p-1'>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>Which free URL shortener is the best?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain provides a dependable and cost-free URL shortener with premium, personalized short URLs.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>How can I easily and for free convert an image to text online?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>To easily extract text from photographs, use the free Image-to-Text Converter from Toolstrain.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>Where can I get a Word-converter PDF for free?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain offers a free and simple-to-use PDF-to-Word converter.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>How can I freely convert a Word document to a PDF?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>A quick and easy Word-to-PDF converter from Toolstrain is available for free.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>Which tool can I use to compress images online without losing quality?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain’s Free Image Size Compressor allows you to compress images while maintaining their quality.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>Where can I generate a dynamic QR code for free?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain provides a free Dynamic QR Code Generator with no sign-up required.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>What is the best link in the bio tool available?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain offers a high-quality link in the bio tool that is perfect for social media profiles.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
                <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                  <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                    <FaqPLink>How can I find my chronological age online?
                    </FaqPLink>
                    <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                  </div>
                </summary>
                <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                  <PLink>Toolstrain’s Free Chronological Age Calculator gives you your exact age instantly.
                  </PLink>
                </div>
              </details>
              <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
              <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
                <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                  <FaqPLink>Which tool allows me to generate random passwords securely?
                  </FaqPLink>
                  <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
                </div>
              </summary>
              <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
                <PLink>Toolstrain’s LastPass Password Generator creates secure, random passwords for free.
                </PLink>
              </div>
            </details>
            <details class="grid grid-cols-1 py-1 border-2 border-Transparent1 rounded-lg [&_svg]:open:-rotate-180">
            <summary class="grid grid-cols-1 cursor-pointer list-none items-center gap-4">
              <div className='grid grid-cols-[1fr_auto] items-center px-2 py-1 group'>
                <FaqPLink>Where can I download YouTube thumbnails for free?
                </FaqPLink>
                <img src={RightButtonIcon} alt='Icon' className='w-8 h-auto' />
              </div>
            </summary>
            <div className='grid px-2 py-3 border-t-2 border-Transparent1 rounded-lg gap-1 sm:gap-2'>
              <PLink>Toolstrain offers a Free YouTube Thumbnail Downloader for easy and quick access.
              </PLink>
            </div>
          </details>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Home