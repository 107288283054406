
import URL from '../assets/icons/website_url.svg';
import BioProfile from '../assets/icons/fingerprint.svg';
import QRCode from '../assets/icons/qr_code.svg';
import {createContext} from 'react';

export const WebServicesContext = createContext();
const WebServicesContextState = (props) => {
    const WebServicesTools = [
        {
          icon: URL,
          alt: 'Boost Visibility With Smart Url Shortener Free Online. Toolstrain.com',
          focusheading: 'Best Free URL Shortener',
          heading: 'Boost Visibility With Smart Url Shortener Free Online',
          description: 'To Avoid those Messy and Long URLs Now You can Easily Use Ouw Best Free URL Shortener! With Reliablility and Hhigh Quality Short URLs you will Love this Tool.',
          toPath:'/',
        },
        {
          icon: BioProfile,
          alt: 'Optimize Your Bio Links for Success Toolstrain.com',
          focusheading: 'Best Link in Bio Tool',
          heading: 'Optimize Your Bio Links for Success',
          description: 'For a High Quality and Best Link in Bio Tool Visit Our Tools Website Toolstrain! You can Create Bio Links for Social Media and Make them Look Good as well.',
          toPath:'/bio',
        },
        {
          icon: QRCode,
          alt: 'Qr Code Generator Toolstrain.com',
          focusheading: 'Free Dynamic QR Code Generator',
          heading: 'Free QR Code Generator - Create QR Code for URL | No Sign Up',
          description: 'Looking for a Free QR Code Generator with No Sign Up? You are in the Right Place! Visit Toolstrain for Free Dynamic QR Code Generator!',
          toPath:'/qr-code-generator',
        },
    ];
    return (
        <WebServicesContext.Provider value={WebServicesTools}>
            {props.children}
        </WebServicesContext.Provider>
    )
}
export default WebServicesContextState;