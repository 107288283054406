// routesData.js
import Home from '../Pages/Home';
import WebServices from '../Pages/WebServices';
import DocumentConversion from '../Pages/DocumentConversion';
import AllTools from '../Pages/AllTools';
import TextAssistanceTool from '../Pages/TextAssistanceTool';
import NotFound from '../Pages/NotFound';
import TermsAndConditions from '../Pages/TermsAndConditions';
import {PrivacyAndPolicy} from '../Pages/PrivacyAndPolicy';
import ImageToTextConvertor from '../Pages/ImageToTextConvertor';
import PdfToWordConvertor from '../Pages/PdfToWordConvertor';
import WordToPdfConvertor from '../Pages/WordToPdfConvertor';
import ConvertToPng from '../Pages/ConvertToPng';
import ImageFileCompressor from '../Pages/ImageFileCompressor';
import BioLinkToolPage from '../Pages/BioLinkToolPage';
import QrCodeGenerator from '../Pages/QrCodeGenerator';
import CreateBioProfile from '../Pages/CreateBioProfile';
import EditBioProfile from '../Pages/EditBioProfile';
import DeleteBioProfile from '../Pages/DeleteBioProfile';
import Profile from '../Pages/Profile';
import AgeCalculator from '../Pages/AgeCalculator';
import YoutubeThumbnailDownloader from '../Pages/YoutubeThumbnailDownloader';
import PasswordGenrator from '../Pages/PasswordGenrator';
import HashTagGenrator from '../Pages/HashTagGenrator';
import WordsCounter from '../Pages/WordsCounter';
import InvoiceGenrator from '../Pages/InvoiceGenrator';
import {About} from '../Pages/About';
import ContactUs from '../Pages/ContactUs';
import EncoderDecoder from '../Pages/EncoderDecoder';
import Blog from '../Pages/Blog';
import FirstBlog from '../Pages/BlogsPosts/FirstBlog';
import HowToDownloadAYoutubeThumbnail from '../Pages/BlogsPosts/HowToDownloadAYoutubeThumbnail';
import HowToMakeLinkedinUrlShortenerFreeToolstrainUrlShortener from '../Pages/BlogsPosts/HowToMakeLinkedinUrlShortenerFreeToolstrainUrlShortener';
import RedirectComponent from '../Utilities/RedirectComponent';
import TinyurlVsBitly from '../Pages/BlogsPosts/TinyurlVsBitly';
import GenerateSitemap from '../Pages/GenerateSitemap';
import AllProductiveTools from '../Pages/AllProductiveTools';
import { Api } from '../Pages/Api';
import FreeUrlShortenerApi from '../Pages/BlogsPosts/FreeUrlShortenerApi';
import ConvertImageToTextAI from '../Pages/BlogsPosts/ConvertImageToTextAI';
import { GenerateSitemap1 } from '../Pages/GenerateSitemap1';

const RoutesData = [
  {
    path: '/',
    component: <Home />,
    index: true,
    Redirect: [
      { value: <RedirectComponent request="index.php" redirectTo="/" /> },
      { value: <RedirectComponent request="index.html" redirectTo="/" /> }
    ]
  },
  {
    path: 'all-tools',
    component: <AllTools />,
    Redirect: [
      { value: <RedirectComponent request="AllTools" redirectTo="all-tools" /> }
    ]
  },
  {
    path: 'document-conversion',
    component: <DocumentConversion />,
    Redirect: [
      { value: <RedirectComponent request="DocumentConversion" redirectTo="document-conversion" /> }
    ]
  },
  {
    path: 'web-services', component: <WebServices />,
    Redirect: [
      { value: <RedirectComponent request="WebServices" redirectTo="web-services" /> }
    ]
  },
  {
    path: 'productivity', component: <AllProductiveTools />
  },
  {
    path: 'text-assistance-tool', component: <TextAssistanceTool />,
    Redirect: [
      { value: <RedirectComponent request="TextAssistanceTool" redirectTo="text-assistance-tool" /> }
    ]
  },
  {
    path: 'about', component: <About />,
    Redirect: [
      { value: <RedirectComponent request="About" redirectTo="about" /> },
      { value: <RedirectComponent request="AboutUs" redirectTo="about" /> }
    ]
  },
  {
    path: 'contact-us', component: <ContactUs />,
    Redirect: [
      { value: <RedirectComponent request="ContactUs" redirectTo="contact-us" /> }
    ]
  },
  {
    path: 'terms-and-conditions', component: <TermsAndConditions />,
    Redirect: [
      { value: <RedirectComponent request="TermsAndConditions" redirectTo="terms-and-conditions" /> }
    ]
  },
  {
    path: 'privacy-and-policy', component: <PrivacyAndPolicy />,
    Redirect: [
      { value: <RedirectComponent request="PrivacyAndPolicy" redirectTo="privacy-and-policy" /> }
    ]
  },
  {
    path: 'image-to-text-convertor', component: <ImageToTextConvertor />,
    Redirect: [
      { value: <RedirectComponent request="ImageToTextConvertor" redirectTo="image-to-text-convertor" /> }
    ]
  },
  {
    path: 'pdf-to-word-convertor', component: <PdfToWordConvertor />,
    Redirect: [
      { value: <RedirectComponent request="PdfToWordConvertor" redirectTo="pdf-to-word-convertor" /> }
    ]
  },
  {
    path: 'word-to-pdf-convertor', component: <WordToPdfConvertor />,
    Redirect: [
      { value: <RedirectComponent request="WordToPdfConvertor" redirectTo="word-to-pdf-convertor" /> }
    ]
  },
  {
    path: 'convert-into-png', component: <ConvertToPng />,
    Redirect: [
      { value: <RedirectComponent request="ConvertToPng" redirectTo="convert-into-png" /> }
    ]
  },
  {
    path: 'image-file-compressor', component: <ImageFileCompressor />,
    Redirect: [
      { value: <RedirectComponent request="ImageFileCompressor" redirectTo="image-file-compressor" /> }
    ]
  },
  {
    path: 'bio', component: <BioLinkToolPage />,
    Redirect: [
      { value: <RedirectComponent request="Bio" redirectTo="bio" /> }
    ]
  },
  {
    path: 'age-calculator', component: <AgeCalculator />, 
    Redirect: [
      { value: <RedirectComponent request="AgeCalculator" redirectTo="age-calculator" /> }
    ]
  },
  {
    path: 'youtube-thumbnail-downloader', component: <YoutubeThumbnailDownloader />,
    Redirect: [
      { value: <RedirectComponent request="YoutubeThumbnailDownloader" redirectTo="youtube-thumbnail-downloader" /> }
    ]
  },
  {
    path: 'password-genrator', component: <PasswordGenrator />, 
    Redirect: [
      { value: <RedirectComponent request="PasswordGenrator" redirectTo="password-genrator" /> }
    ]
  },
  {
    path: 'hash-tag-genrator', component: <HashTagGenrator />, 
    Redirect: [
      { value: <RedirectComponent request="HashTagGenrator" redirectTo="hash-tag-genrator" /> }
    ]
  },
  {
    path: 'encoder-decoder', component: <EncoderDecoder />, 
    Redirect: [
      { value: <RedirectComponent request="EncoderDecoder" redirectTo="encoder-decoder" /> }
    ]
  },
  {
    path: 'words-counter', component: <WordsCounter />, 
    Redirect: [
      { value: <RedirectComponent request="WordsCounter" redirectTo="words-counter" />  }
    ]
  },
  {
    path: 'invoice-genrator', component: <InvoiceGenrator />,  
    Redirect: [
      { value: <RedirectComponent request="InvoiceGenrator" redirectTo="invoice-genrator" />  }
    ]
  },
  {
    path: 'blog', component: <Blog />,
    Redirect: [
      { value: <RedirectComponent request="Blogs" redirectTo="blog" />  }
    ]
  },
  {
    path: 'blog/what-is-a-url-shortener', component: <FirstBlog />,
  },
  { path: 'blog/download-youtube-thumbnail', component: <HowToDownloadAYoutubeThumbnail />,
    Redirect: [
      { value: <RedirectComponent request="blog/how-to-download-a-youtube-thumbnail" redirectTo="download-youtube-thumbnail" />  }
    ]
  },
  { path: 'blog/How-To-Make-Linkedin-Url-Shortener-Free-Toolstrain-Url-Shortener', component: <HowToMakeLinkedinUrlShortenerFreeToolstrainUrlShortener /> },
  { path: 'blog/tinyurl-vs-bitly', component: <TinyurlVsBitly /> },
  { path: 'api', component: <Api /> },
  { path: 'sitemapgenrator', component: <GenerateSitemap /> },
  { path: 'sitemapgenrator1', component: <GenerateSitemap1 /> },
  { path: 'qr-code-generator', component: <QrCodeGenerator /> },
  { path: 'blog/free-url-shortener-api', component: <FreeUrlShortenerApi /> },
  { path: 'blog/convert-image-to-text-ai', component: <ConvertImageToTextAI /> },
  { path: 'profile/:id', component: <Profile /> },
  { path: 'create-profile', component: <CreateBioProfile /> },
  { path: 'edit-profile/:id', component: <EditBioProfile /> },
  { path: 'delete-bio-profile/:id', component: <DeleteBioProfile /> },
  { path: '*', component: <NotFound /> },
];

export default RoutesData;